import React, { FC, useState } from 'react';
import FileSaver from 'file-saver';
import * as analytics from '../helper/analytics';
import { IExpertPdfParas } from '../models/ExpertPdfParas';
import { ExportCompareDataPDF } from '../api/ComparisonData';
import { keyUpWrapper } from '../helper/common';
import { ConstantClass } from '../helper/ConstantClass';
import Moment from 'moment';

interface IProps {
  viewType: string;
  locationCodes: string[];
}

export const SaveAsPDF: FC<IProps> = ({ viewType, locationCodes }) => {
  const [pdfLabel, setPdfLabel] = useState<string>('Save As PDF');
  const [pdfLabelDisabled, setPdfLabelDisabled] = useState<string>('');

  ////////////////////////////////////////////////////////////////////////
  function exportPDFWindow() {
    window.print();
  }
  function exportPDF() {
    setPdfLabel('Processing...');
    setPdfLabelDisabled('disabled');
    let currentUrl: string = window.location.href;
    if (currentUrl.indexOf('?') > -1) {
      currentUrl = currentUrl + '&showMode=export';
    } else {
      currentUrl = currentUrl + '?showMode=export';
    }

    const pdfParas: IExpertPdfParas = {
      urlToRender: currentUrl,
      exportFormat: 1, // 1: PDFPortrait, 2: PDFLandscape
      myVirtualDirectoryOnDev: 'https://places-dev.apps.ecpaas-dev.cdc.gov/',
      exportFileName: getExportFileName(),
      conversionDelaySec: 10,
    };

    const exportToPDFAPI = async () => {
      return await ExportCompareDataPDF(pdfParas);
    };
    //let result: Blob | string = '';
    exportToPDFAPI().then((res) => {
      //result = res;
      if (res) {
        FileSaver.saveAs(res, pdfParas.exportFileName);
      }
      setPdfLabel('Save As PDF');
      setPdfLabelDisabled('');
    });
  }
  function getExportFileName() {
    const dt = Moment().format('MMDDYYYY').toString();
    if (viewType === ConstantClass.County) {
      return `CountiesCompareData_${dt}.pdf`;
    } else {
      return `PlacesCompareData_${dt}.pdf`;
    }
  }
  /////////////////////////////////////////////////////////////////////////////
  return (
    <div
      onClick={() => {
        analytics.saveAsPDF(viewType, locationCodes);
        exportPDFWindow();
      }}
      onKeyUp={(e) => keyUpWrapper(e, exportPDFWindow)}
      role="button"
      tabIndex={0}
      className={`link topic-csv ${pdfLabelDisabled}`}
    >
      <span className="x24 fill-p cdc-icon-pdf me-1"></span>
      <span>{pdfLabel}</span>
    </div>
  );
};
