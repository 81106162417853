import React, { FC, useState, useRef, useEffect, ChangeEvent } from 'react';
import { keyUpWrapper } from '../helper/common';
import Modal from 'react-modal';
import { IStatesAndCity } from '../models/StatesAndCity';
import { GetStatesAndCities } from '../api/ComparisonData';

// this helps with Accessibility by setting the aria-hidden to true on
// the main element when the modal is open.
// https://reactcommunity.org/react-modal/examples/set_app_element/
Modal.setAppElement('#root');

interface IProps {
  locationCities: IStatesAndCity[] | null;
  cityCodes: string[];
  editCityCode: string | null;
  setEditCityCode: (fieldName: string | null) => void;
  openCityModal: boolean;
  setLocationCities: (fieldName: IStatesAndCity[] | null) => void;
  setCityCodes: (fieldName: string[]) => void;
  setOpenCityModal: (fieldName: boolean) => void;
}

export const LocationCityModal: FC<IProps> = ({
  locationCities,
  cityCodes,
  editCityCode,
  setEditCityCode,
  openCityModal,
  setLocationCities,
  setCityCodes,
  setOpenCityModal,
}) => {
  const [searchedLocations, setSearchedLocations] = useState<IStatesAndCity[] | null>(null);
  const [states, setStates] = useState<IStatesAndCity[] | null>(null);
  const [thisCityCode, setThisCityCode] = useState<string | null>(null);
  const rootElement = document.querySelector<HTMLElement>('#root');
  const contentRef = useRef<HTMLDivElement | null>(null);
  const searchStrRef = useRef<HTMLInputElement | null>(null);
  const [openAll, setOpenAll] = useState<boolean>(false);

  //////////////////////////////////////// EFFECTS /////////////////////////////////////////////
  //1.Get locationCities
  useEffect(() => {
    const getLocationsAPI = async () => {
      const apiLocations = await GetStatesAndCities();
      setLocationCities(apiLocations);
    };
    //get/set locationCities
    if (locationCities == null) {
      getLocationsAPI();
    }
    //on first open, search string is null so searchedLocations = locationCities
    setSearchedLocations(locationCities);
  }, [locationCities, setLocationCities]);

  //2.Get Search locationCities and States
  useEffect(() => {
    //set umique location
    setStates(getStates());

    //console.log('state count: ' + states?.length);
    //console.log(JSON.stringify(states, null, 2));
    function getStates(): IStatesAndCity[] {
      if (searchedLocations != null) {
        let uniqueLocation = new Map();
        for (let loc of searchedLocations) {
          //delete loc.ParentId1;
          uniqueLocation.set(loc.stratificationAbbr, loc);
        }
        return [...uniqueLocation.values()];
      } else {
        return [];
      }
    }
  }, [searchedLocations]);

  //3.Set editCityCode
  useEffect(() => {
    setThisCityCode(editCityCode);
  }, [editCityCode]);

  //4.Expand/Collapse states
  useEffect(() => {
    states?.forEach(({ stratificationAbbr }) => {
      let pmIcon = document.getElementById(stratificationAbbr);
      let cityDiv = document.getElementById('city' + stratificationAbbr);
      if (pmIcon && cityDiv) {
        if (openAll) {
          cityDiv.style.display = 'block';
          pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(-180deg)';
        } else {
          cityDiv.style.display = 'none';
          pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(0deg)';
        }
      }
    });
  }, [openAll, states]);

  //Resize Modal
  useEffect(() => {
    // this will handle repositioning the Modal
    // when the user resizes the browser.
    function setSize() {
      if (openCityModal && contentRef.current) {
        const { left, right, height } = calcStyle();
        contentRef.current.style.left = left;
        contentRef.current.style.right = right;
        contentRef.current.style.height = height;
      }
    }

    window.addEventListener('resize', setSize);
    if (openCityModal) {
      // because the location modal remains "mounted"
      // even though it has been close. The "state" of the component
      // persists. So we need to reset the "checked items"
      // back to what was passed down from the parent which are the
      // "locationIds"
      //setCheckedItems(locationIds);
    }
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [openCityModal]);

  //////////////////////////////////// FUNCTIONS ///////////////////////////////////////
  function diaplayCities(stateCode: string) {
    let cities: IStatesAndCity[] | undefined = searchedLocations?.filter(
      (location) => location.stratificationAbbr === stateCode,
    );
    let citiesHtml = [];
    if (cities) {
      for (let city of cities) {
        if (!cityCodes.includes(city.code)) {
          citiesHtml.push(
            <div
              tabIndex={0}
              role="button"
              key={city.code}
              className="link px-1"
              style={{ cursor: 'hand' }}
              onClick={(e) => CityClicked(e, city.code)}
              onKeyUp={(e) => keyUpWrapper(e, CityClicked, city.code)}
            >
              {city.label}
            </div>,
          );
        }
      }
    }
    return citiesHtml;
  }
  function getCityName() {
    let cityName = '';
    if (thisCityCode) {
      let cities = locationCities?.filter((location) => location.code === thisCityCode);
      if (cities && cities.length > 0) {
        cityName = cities[0].label + ', ' + cities[0].stratificationAbbr;
      }
    }
    return cityName;
  }
  function calcStyle() {
    const width = 400;
    const fromScreenEdge = `${window.innerWidth / 2 - width / 2}px`;
    const topBottom = 40; // 40px is the default set by React Modal
    const height = `${window.innerHeight - topBottom * 5}px`;
    return {
      left: fromScreenEdge,
      right: fromScreenEdge,
      height,
      padding: 0,
      overflow: 'visible',
    };
  }
  const scroll = {
    // this disables/enables the scrolling behavior
    // on the root element only so that the scroll
    // inside the modal is the only one available
    disable: () => {
      if (rootElement != null) {
        rootElement.style.position = 'fixed';
        rootElement.style.width = '100%';
      }
    },
    enable: () => {
      if (rootElement != null) {
        rootElement.style.position = 'relative';
        rootElement.style.width = 'auto';
      }
    },
  };

  //////////////////////////////////// HANDLE EVENTS ///////////////////////////////////////
  function AddCityClicked(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    setThisCityCode(null);
    setOpenCityModal(true);
  }
  function SubmitAddClicked(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (thisCityCode != null && !cityCodes?.includes(thisCityCode)) {
      if (editCityCode != null && thisCityCode !== editCityCode) {
        let newCityCodesEdit = cityCodes.slice(); //copy the array
        var index = newCityCodesEdit.indexOf(editCityCode);
        if (index !== -1) {
          newCityCodesEdit[index] = thisCityCode;
          setCityCodes(newCityCodesEdit);
        }
      } else {
        let newCityCodesAdd = [...cityCodes, thisCityCode];
        setCityCodes(newCityCodesAdd);
      }
    }
    CloseModal();
  }
  function SubmitCancelClicked(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    CloseModal();
  }
  function CloseModal() {
    setEditCityCode(null);
    setEditCityCode(null);
    setOpenCityModal(false);
  }
  function StateClicked(e: React.MouseEvent<HTMLElement>, stateAbbr: string) {
    //e.preventDefault();
    let pmIcon = document.getElementById(stateAbbr);
    let cityDiv = document.getElementById('city' + stateAbbr);
    if (pmIcon && cityDiv) {
      if (cityDiv.style.display === 'none') {
        cityDiv.style.display = 'block';
        pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(-180deg)';
      } else {
        cityDiv.style.display = 'none';
        pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(0deg)';
      }
    }
    //console.log(e.currentTarget.id);
  }
  function CityClicked(e: React.MouseEvent<HTMLElement>, cityCodeSelected: string) {
    if (cityCodeSelected) {
      setThisCityCode(cityCodeSelected);
    }
  }
  function SetOpenAll(e: React.MouseEvent<HTMLElement>, thisOpenAll: boolean) {
    setOpenAll(thisOpenAll);
  }
  function SearchChanged(e: ChangeEvent<HTMLInputElement>) {
    if (locationCities && searchStrRef.current) {
      let searchStr = searchStrRef.current.value === null ? '' : searchStrRef.current.value.trim().toLowerCase();
      let fountLocations = locationCities?.filter((location) => location.label.toLowerCase().startsWith(searchStr));
      console.log(JSON.stringify(fountLocations, null, 2));
      setSearchedLocations(fountLocations);
      setOpenAll(true);
    }
  }
  //////////////////////////////////////// RETURN ///////////////////////////////////////
  return (
    <>
      <button
        tabIndex={0}
        disabled={cityCodes.length >= 3}
        type="button"
        className="btn btn-primary btn-sm"
        onClick={AddCityClicked}
      >
        Add Place
      </button>
      <Modal
        isOpen={openCityModal}
        parentSelector={() => rootElement!}
        style={{ content: calcStyle() }}
        contentRef={(node) => (contentRef.current = node)}
        onAfterOpen={scroll.disable}
        onAfterClose={scroll.enable}
      >
        <div className="bg-primary p-1 ps-2 modal-text-size">
          <strong>Select Place</strong>
        </div>
        <div className="mt-2 ms-2 modal-text-size">
          <strong>Selected Place:</strong> <label id="selectedCity">{getCityName()}</label>
        </div>
        <div className="m-2 modal-text-size">
          <label>Search for places starting with</label>
          <input id="txtSearch" onChange={SearchChanged} ref={searchStrRef} style={{ width: '100%' }} />
        </div>
        <div className="m-2 d-flex justify-content-between modal-text-size">
          <div>Browse All Places</div>
          <div>
            <span
              className="link px-1"
              role="button"
              tabIndex={0}
              onClick={(e) => SetOpenAll(e, true)}
              onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, true)}
            >
              Expand All
            </span>
            /
            <span
              className="link px-1"
              role="button"
              tabIndex={0}
              onClick={(e) => SetOpenAll(e, false)}
              onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, false)}
            >
              Collapse All
            </span>
          </div>
        </div>
        <div className="modal__form px-10 modal-text-size m-2">
          <div className="modal__checks border p-2">
            {states?.map((state) => (
              <div key={state.stratificationAbbr} className="topic-collapse" role="button" tabIndex={0}>
                <span
                  id={state.stratificationAbbr}
                  role="button"
                  tabIndex={0}
                  className="fi cdc-icon-plus topic-collapse__plusminus"
                  aria-hidden="true"
                  onClick={(e) => StateClicked(e, state.stratificationAbbr)}
                  onKeyUp={(e) => keyUpWrapper(e, StateClicked, state.stratificationAbbr)}
                ></span>
                <label
                  htmlFor={state.stratificationAbbr}
                  className="ms-1 pb-0"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => StateClicked(e, state.stratificationAbbr)}
                  onKeyUp={(e) => keyUpWrapper(e, StateClicked, state.stratificationAbbr)}
                >
                  {state.parentId2}
                </label>
                <div id={'city' + state.stratificationAbbr} className="ms-4 mb-2" style={{ display: 'none' }}>
                  {diaplayCities(state.stratificationAbbr)}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center pt-4 pb-4 modal-text-size">
            <button className="btn btn-primary mx-1" onClick={SubmitAddClicked} tabIndex={0}>
              Add
            </button>
            <button className="btn btn-light mx-1" onClick={SubmitCancelClicked} tabIndex={0}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
