import { http } from './Http';
import { ICategory } from '../models/Category';
import { IFootnoteSymbol } from '../models/FootnoteSymbol';
import { IPageLevelFootnote } from '../models/PageLevelFootnote';
import { IPanelLevelFootnote } from '../models/PanelLevelFootnote';
import { IStatesAndCounty } from '../models/StatesAndCounty';
import { ICompareData } from '../models/CompareData';
import { ICompareDataCSV } from '../models/CompareDataCSV';
import { IExpertPdfParas } from '../models/ExpertPdfParas';
import { IStatesAndCity } from '../models/StatesAndCity';

///////////////////////////////////////////////////////////////////////////////////////////////
export const GetStatesAndCounties = async (): Promise<IStatesAndCounty[]> => {
  try {
    const result = await http<undefined, IStatesAndCounty[]>({
      path: '/Comparison/StatesAndCounties',
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

export const GetStatesAndCities = async (): Promise<IStatesAndCity[]> => {
  try {
    const result = await http<undefined, IStatesAndCity[]>({
      path: '/Comparison/StatesAndCities',
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////
export const GetCategories = async (viewTYpe: string, locationId: string): Promise<ICategory[]> => {
  try {
    const result = await http<undefined, ICategory[]>({
      path: `/Comparison/Categories?viewType=${viewTYpe}&locationId=${locationId}`,
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////
export const GetCompareData = async (viewTYpe: string, locationId: string): Promise<ICompareData[]> => {
  try {
    const result = await http<undefined, ICompareData[]>({
      path: `/Comparison/CompareData?viewType=${viewTYpe}&locationId=${locationId}`,
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////
export const GetFootnoteSymbols = async (viewTYpe: string, locationId: string): Promise<IFootnoteSymbol[]> => {
  try {
    const result = await http<undefined, IFootnoteSymbol[]>({
      path: `/Comparison/FootNoteSymbols?viewType=${viewTYpe}&locationId=${locationId}`,
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////
export const GetPageLevelFootnotes = async (): Promise<IPageLevelFootnote[]> => {
  try {
    const result = await http<undefined, IPageLevelFootnote[]>({
      path: '/Comparison/PageLevelFootnotes',
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////
export const GetPanelLevelFootnotes = async (): Promise<IPanelLevelFootnote[]> => {
  try {
    const result = await http<undefined, IPanelLevelFootnote[]>({
      path: '/Comparison/PanelLevelFootnotes',
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////+
export const ExportCompareDataCSV = async (viewTYpe: string, locationId: string): Promise<ICompareDataCSV[]> => {
  try {
    const result = await http<undefined, ICompareDataCSV[]>({
      path: `/Comparison/CompareDataCSV?viewType=${viewTYpe}&locationId=${locationId}`,
    });
    if (result.parsedBody) {
      return result.parsedBody;
    } else {
      return [];
    }
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////
export const ExportCompareDataPDF = async (pdfParas: IExpertPdfParas): Promise<Blob | ''> => {
  try {
    const result = await http<IExpertPdfParas, Blob>({
      path: `/Export/PostExportToPdf`,
      method: 'post',
      body: pdfParas,
      responseType: 'blob',
    });
    if (result.ok && result.parsedBody) {
      return result.parsedBody;
    } else {
      return '';
    }
  } catch (ex) {
    console.error(ex);
    return '';
  }
};
