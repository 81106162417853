import React from 'react';
import { Title } from '../tp4';

export const PdfHeader = () => {
  return (
    <div className="container p-0">
      <div className="row m-0 border bg-light ps-0 pt-2 pb-2">
        <div className="col-10 ps-0 ms-0 text-center">
          <Title title="PLACES: Local Data for Better Health Compare Counties Report" />
        </div>
        <div className="col-2">
          <img src="/logo1.png" width={140} height={80} alt="logo" />
        </div>
      </div>
    </div>
  );
};
