import React from 'react';

export const SkipNav = () => {
  return (
    <div id="skipmenu">
      <a className="skippy sr-only-focusable" href="#searchInput">
        Skip directly to site content
      </a>
    </div>
  );
};
