import { doInteraction, doAnalytics } from '../tp4/assets/js/doAnalytics';

export function pageLoad() {
  doAnalytics({
    levels: ['Chronic', 'DPH', 'DPH Apps'],
    AzEntry: '',
    channel: 'DPH PLACES DTM Open Data',
  });
}

// User clicks Save on Add/Remove modal
export function changeLocation(view: string, locationIds: string[]) {
  doInteraction(`DPH PLACES DTM-CRL ${view} ${locationIds.join('-')}`);
}

// User clicks Save as CSV
export function saveAsCSV(view: string, locationIds: string[]) {
  doInteraction(`DPH PLACES DTM-CRE ${view} ${locationIds.join('-')},CSV`);
}

// User clicks Save as CSV
export function saveAsPDF(view: string, locationIds: string[]) {
  doInteraction(`DPH PLACES DTM-CRE ${view} ${locationIds.join('-')},PDF`);
}
