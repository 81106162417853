import React, { FC, useState, useRef, useEffect, ChangeEvent } from 'react';
import { keyUpWrapper } from '../helper/common';
import Modal from 'react-modal';
import { IStatesAndCounty } from '../models/StatesAndCounty';
import { GetStatesAndCounties } from '../api/ComparisonData';

// this helps with Accessibility by setting the aria-hidden to true on
// the main element when the modal is open.
// https://reactcommunity.org/react-modal/examples/set_app_element/
Modal.setAppElement('#root');

interface IProps {
  locationCounties: IStatesAndCounty[] | null;
  countyCodes: string[];
  editCountyCode: string | null;
  setEditCountyCode: (fieldName: string | null) => void;
  openCountyModal: boolean;
  setLocationCounties: (fieldName: IStatesAndCounty[] | null) => void;
  setCountyCodes: (fieldName: string[]) => void;
  setOpenCountyModal: (fieldName: boolean) => void;
}

export const LocationCountyModal: FC<IProps> = ({
  locationCounties,
  countyCodes,
  editCountyCode,
  setEditCountyCode,
  openCountyModal,
  setLocationCounties,
  setCountyCodes,
  setOpenCountyModal,
}) => {
  const [searchedLocations, setSearchedLocations] = useState<IStatesAndCounty[] | null>(null);
  const [states, setStates] = useState<IStatesAndCounty[] | null>(null);
  const [thisCountyCode, setThisCountyCode] = useState<string | null>(null);
  const rootElement = document.querySelector<HTMLElement>('#root');
  const contentRef = useRef<HTMLDivElement | null>(null);
  const searchStrRef = useRef<HTMLInputElement | null>(null);
  const [openAll, setOpenAll] = useState<boolean>(false);

  //////////////////////////////////////// EFFECTS /////////////////////////////////////////////
  //1.Get locationCounties
  useEffect(() => {
    const getLocationsAPI = async () => {
      const apiLocations = await GetStatesAndCounties();
      setLocationCounties(apiLocations);
    };
    //get/set locationCounties
    if (locationCounties == null) {
      getLocationsAPI();
    }
    //on first open, search string is null so searchedLocations = locationCounties
    setSearchedLocations(locationCounties);
  }, [locationCounties, setLocationCounties]);

  //2.Get Search locationCounties and States
  useEffect(() => {
    //set umique location
    setStates(getStates());

    //console.log('state count: ' + states?.length);
    //console.log(JSON.stringify(states, null, 2));
    function getStates(): IStatesAndCounty[] {
      if (searchedLocations != null) {
        let uniqueLocation = new Map();
        for (let loc of searchedLocations) {
          //delete loc.ParentId1;
          uniqueLocation.set(loc.stratificationAbbr, loc);
        }
        return [...uniqueLocation.values()];
      } else {
        return [];
      }
    }
  }, [searchedLocations]);

  //3.Set editCountyCode
  useEffect(() => {
    setThisCountyCode(editCountyCode);
  }, [editCountyCode]);

  //4.Expand/Collapse states
  useEffect(() => {
    states?.forEach(({ stratificationAbbr }) => {
      let pmIcon = document.getElementById(stratificationAbbr);
      let countyDiv = document.getElementById('county' + stratificationAbbr);
      if (pmIcon && countyDiv) {
        if (openAll) {
          countyDiv.style.display = 'block';
          pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(-180deg)';
        } else {
          countyDiv.style.display = 'none';
          pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(0deg)';
        }
      }
    });
  }, [openAll, states]);

  //Resize Modal
  useEffect(() => {
    // this will handle repositioning the Modal
    // when the user resizes the browser.
    function setSize() {
      if (openCountyModal && contentRef.current) {
        const { left, right, height } = calcStyle();
        contentRef.current.style.left = left;
        contentRef.current.style.right = right;
        contentRef.current.style.height = height;
      }
    }

    window.addEventListener('resize', setSize);
    if (openCountyModal) {
      // because the location modal remains "mounted"
      // even though it has been close. The "state" of the component
      // persists. So we need to reset the "checked items"
      // back to what was passed down from the parent which are the
      // "locationIds"
      //setCheckedItems(locationIds);
    }
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [openCountyModal]);

  //////////////////////////////////// FUNCTIONS ///////////////////////////////////////
  function diaplayCounties(stateCode: string) {
    let counties: IStatesAndCounty[] | undefined = searchedLocations?.filter(
      (location) => location.stratificationAbbr === stateCode,
    );
    let countiesHtml = [];
    if (counties) {
      for (let county of counties) {
        if (!countyCodes.includes(county.code)) {
          countiesHtml.push(
            <div
              tabIndex={0}
              role="button"
              key={county.code}
              className="link px-1"
              style={{ cursor: 'hand' }}
              onClick={(e) => CountyClicked(e, county.code)}
              onKeyUp={(e) => keyUpWrapper(e, CountyClicked, county.code)}
            >
              {county.label}
            </div>,
          );
        }
      }
    }
    return countiesHtml;
  }
  function getCountyName() {
    let countyName = '';
    if (thisCountyCode) {
      let counties = locationCounties?.filter((location) => location.code === thisCountyCode);
      if (counties && counties.length > 0) {
        countyName = counties[0].label + ', ' + counties[0].stratificationAbbr;
      }
    }
    return countyName;
  }
  function calcStyle() {
    const width = 400;
    const fromScreenEdge = `${window.innerWidth / 2 - width / 2}px`;
    const topBottom = 40; // 40px is the default set by React Modal
    const height = `${window.innerHeight - topBottom * 5}px`;
    return {
      left: fromScreenEdge,
      right: fromScreenEdge,
      height,
      padding: 0,
      overflow: 'visible',
    };
  }

  const scroll = {
    // this disables/enables the scrolling behavior
    // on the root element only so that the scroll
    // inside the modal is the only one available
    disable: () => {
      if (rootElement != null) {
        rootElement.style.position = 'fixed';
        rootElement.style.width = '100%';
      }
    },
    enable: () => {
      if (rootElement != null) {
        rootElement.style.position = 'relative';
        rootElement.style.width = 'auto';
      }
    },
  };

  //////////////////////////////////// HANDLE EVENTS ///////////////////////////////////////
  function AddCountyClicked(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    setThisCountyCode(null);
    setOpenCountyModal(true);
  }

  function SubmitAddClicked(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (thisCountyCode != null && !countyCodes?.includes(thisCountyCode)) {
      if (editCountyCode && thisCountyCode !== editCountyCode) {
        let newCountyCodesEdit = countyCodes.slice(); //copy the array
        var index = newCountyCodesEdit.indexOf(editCountyCode);
        if (index !== -1) {
          newCountyCodesEdit[index] = thisCountyCode;
          setCountyCodes(newCountyCodesEdit);
        }
      } else {
        let newCountyCodesAdd = [...countyCodes, thisCountyCode];
        setCountyCodes(newCountyCodesAdd);
      }
    }
    CloseModal();
  }
  function SubmitCancelClicked(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    CloseModal();
  }
  function CloseModal() {
    setEditCountyCode(null);
    setThisCountyCode(null);
    setOpenCountyModal(false);
  }
  function StateClicked(e: React.MouseEvent<HTMLElement>, stateAbbr: string) {
    //e.preventDefault();
    let pmIcon = document.getElementById(stateAbbr);
    let countyDiv = document.getElementById('county' + stateAbbr);
    if (pmIcon && countyDiv) {
      if (countyDiv.style.display === 'none') {
        countyDiv.style.display = 'block';
        pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(-180deg)';
      } else {
        countyDiv.style.display = 'none';
        pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(0deg)';
      }
    }
    //console.log(e.currentTarget.id);
  }
  function CountyClicked(e: React.MouseEvent<HTMLElement>, countyCodeSelected: string) {
    if (countyCodeSelected) {
      setThisCountyCode(countyCodeSelected);
    }
  }
  function SetOpenAll(e: React.MouseEvent<HTMLElement>, thisOpenAll: boolean) {
    setOpenAll(thisOpenAll);
  }
  function SearchChanged(e: ChangeEvent<HTMLInputElement>) {
    if (locationCounties && searchStrRef.current) {
      let searchStr = searchStrRef.current.value === null ? '' : searchStrRef.current.value.trim().toLowerCase();
      let fountLocations = locationCounties?.filter((location) => location.label.toLowerCase().startsWith(searchStr));
      console.log(JSON.stringify(fountLocations, null, 2));
      setSearchedLocations(fountLocations);
      setOpenAll(true);
    }
  }
  //////////////////////////////////////// RETURN ///////////////////////////////////////
  return (
    <>
      <button
        tabIndex={0}
        disabled={countyCodes.length >= 3}
        type="button"
        className="btn btn-primary btn-sm"
        onClick={AddCountyClicked}
      >
        Add County
      </button>
      <Modal
        isOpen={openCountyModal}
        parentSelector={() => rootElement!}
        style={{ content: calcStyle() }}
        contentRef={(node) => (contentRef.current = node)}
        onAfterOpen={scroll.disable}
        onAfterClose={scroll.enable}
      >
        <div className="bg-primary p-1 ps-2 modal-text-size">
          <strong>Select County</strong>
        </div>
        <div className="mt-2 ms-2 modal-text-size">
          <strong>Selected County:</strong> <label id="selectedCounty">{getCountyName()}</label>
        </div>
        <div className="m-2 modal-text-size">
          <label>Search for counties starting with</label>
          <input id="txtSearch" onChange={SearchChanged} ref={searchStrRef} style={{ width: '100%' }} />
        </div>
        <div className="m-2 d-flex justify-content-between modal-text-size">
          <div>Browse All Counties</div>
          <div>
            <span
              className="link px-1"
              role="button"
              tabIndex={0}
              onClick={(e) => SetOpenAll(e, true)}
              onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, true)}
            >
              Expand All
            </span>
            /
            <span
              className="link px-1"
              role="button"
              tabIndex={0}
              onClick={(e) => SetOpenAll(e, false)}
              onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, false)}
            >
              Collapse All
            </span>
          </div>
        </div>
        <div className="modal__form px-10 modal-text-size m-2">
          <div className="modal__checks border p-2">
            {states?.map((state) => (
              <div key={state.stratificationAbbr} className="topic-collapse" role="button" tabIndex={0}>
                <span
                  id={state.stratificationAbbr}
                  role="button"
                  tabIndex={0}
                  className="fi cdc-icon-plus topic-collapse__plusminus"
                  aria-hidden="true"
                  onClick={(e) => StateClicked(e, state.stratificationAbbr)}
                  onKeyUp={(e) => keyUpWrapper(e, StateClicked, state.stratificationAbbr)}
                ></span>
                <label
                  htmlFor={state.stratificationAbbr}
                  className="ms-1 pb-0"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => StateClicked(e, state.stratificationAbbr)}
                  onKeyUp={(e) => keyUpWrapper(e, StateClicked, state.stratificationAbbr)}
                >
                  {state.parentId2}
                </label>
                <div id={'county' + state.stratificationAbbr} className="ms-4 mb-2" style={{ display: 'none' }}>
                  {diaplayCounties(state.stratificationAbbr)}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center pt-4 pb-4 modal-text-size">
            <button className="btn btn-primary mx-1" onClick={SubmitAddClicked} tabIndex={0}>
              Add
            </button>
            <button className="btn btn-light mx-1" onClick={SubmitCancelClicked} tabIndex={0}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
