import React, { FC, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import ReactHtmlParser from 'html-react-parser';

// this helps with Accessibility by setting the aria-hidden to true on
// the main element when the modal is open.
// https://reactcommunity.org/react-modal/examples/set_app_element/
Modal.setAppElement('#root');

interface IProps {
  openConfirmModal: boolean;
  setOpenConfirmModal: (fieldName: boolean) => void;
  removeLocationName: string;
  confirmModalReturn: (fieldName: string) => void;
}

export const ConfirmModal: FC<IProps> = ({
  openConfirmModal,
  setOpenConfirmModal,
  removeLocationName,
  confirmModalReturn,
}) => {
  const rootElement = document.querySelector<HTMLElement>('#root');
  const contentRef = useRef<HTMLDivElement | null>(null);
  const warningMessage: string = 'Are you sure you want to remove the location [location] from the report?';

  //////////////////////////////////////// EFFECTS /////////////////////////////////////////////
  //Resize Modal
  useEffect(() => {
    // this will handle repositioning the Modal
    // when the user resizes the browser.
    function setSize() {
      if (openConfirmModal && contentRef.current) {
        const { top, left, right, height } = calcStyle();
        contentRef.current.style.top = top;
        contentRef.current.style.left = left;
        contentRef.current.style.right = right;
        contentRef.current.style.height = height;
      }
    }

    window.addEventListener('resize', setSize);
    if (openConfirmModal) {
      // because the location modal remains "mounted"
      // even though it has been close. The "state" of the component
      // persists. So we need to reset the "checked items"
      // back to what was passed down from the parent which are the
      // "locationIds"
      //setCheckedItems(locationIds);
      //test
    }
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [openConfirmModal]);

  //////////////////////////////////// FUNCTIONS ///////////////////////////////////////
  function calcStyle() {
    const width = 500;
    const height = '200px';
    const fromScreenEdge = `${window.innerWidth / 2 - width / 2}px`;
    return {
      top: '30%',
      left: fromScreenEdge,
      right: fromScreenEdge,
      height,
      padding: 0,
      overflow: 'visible',
    };
  }
  function getWarmingMessage() {
    return warningMessage.replace('[location]', `<strong>${removeLocationName}</strong>`);
  }

  //////////////////////////////////// HANDLE EVENTS ///////////////////////////////////////
  function SubmitClicked(e: React.MouseEvent<HTMLElement>, answer: string) {
    e.preventDefault();
    confirmModalReturn(answer);
    setOpenConfirmModal(false);
  }
  //////////////////////////////////////// RETURN ///////////////////////////////////////
  return (
    <>
      <Modal
        isOpen={openConfirmModal}
        parentSelector={() => rootElement!}
        style={{ content: calcStyle() }}
        contentRef={(node) => (contentRef.current = node)}
      >
        <div className="bg-primary p-1 ps-2 modal-text-size">
          <strong>Remove County</strong>
        </div>
        <div className="row pt-2">
          <div className="col-2 pt-1 d-flex justify-content-center">
            <span className="x24 fill-p cdc-icon-exclamation-triangle"></span>
          </div>
          <div className="col-10">{ReactHtmlParser(getWarmingMessage())}</div>
        </div>
        <div className="d-flex justify-content-center pt-4 pb-4 modal-text-size">
          <button className="btn btn-primary mx-1" onClick={(e) => SubmitClicked(e, 'remove')} tabIndex={0}>
            Remove
          </button>
          <button type="button" tabIndex={0} className="btn btn-light mx-1" onClick={(e) => SubmitClicked(e, 'cancel')}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
