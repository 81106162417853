import React, { FC, useState, FormEvent, ChangeEvent } from 'react';
import { keyUpWrapper } from '../helper/common';
import { ConstantClass } from '../helper/ConstantClass';

interface Props {
  viewType: string;
  setSearchTextCounty: (fieldName: string) => void;
  setSearchCountCounty: (fieldName: number | undefined) => void;
  searchCountCounty?: number;
  setSearchTextCity: (fieldName: string) => void;
  setSearchCountCity: (fieldName: number | undefined) => void;
  searchCountCity?: number;
}

export const Search: FC<Props> = ({
  viewType,
  setSearchTextCounty,
  setSearchCountCounty,
  searchCountCounty,
  setSearchTextCity,
  setSearchCountCity,
  searchCountCity,
}) => {
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  //When search string changed
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchInputValue(e.currentTarget.value);
  }
  //Clear search string
  function handleClear() {
    setSearchInputValue('');
    if (viewType === ConstantClass.County) {
      setSearchTextCounty('');
      setSearchCountCounty(undefined);
    }
    if (viewType === ConstantClass.City) {
      setSearchTextCity('');
      setSearchCountCity(undefined);
    }
  }
  function getSearchCount() {
    if (viewType === ConstantClass.County) {
      return searchCountCounty;
    }
    if (viewType === ConstantClass.City) {
      return searchCountCity;
    }
    return undefined;
  }
  //Submit
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!searchInputValue) {
      handleClear();
      return;
    }
    if (viewType === ConstantClass.County) {
      setSearchTextCounty(searchInputValue);
    }
    if (viewType === ConstantClass.City) {
      setSearchTextCity(searchInputValue);
    }
  }
  //Return
  return (
    <form onSubmit={handleSubmit}>
      <label className="px-1" htmlFor="searchInput">
        Search Report:
      </label>
      <input
        id="searchInput"
        type="text"
        onChange={handleChange}
        value={searchInputValue}
        style={{ width: '50%' }}
      ></input>
      <input type="submit" value="Search"></input>
      <span
        tabIndex={0}
        role="button"
        className="link px-2"
        onClick={handleClear}
        onKeyUp={(e) => keyUpWrapper(e, handleClear)}
      >
        Clear
      </span>
      {getSearchCount() !== undefined && <span className="search-count">{getSearchCount()} Results</span>}
    </form>
  );
};
