import React from 'react';
import { Env } from '../AppSettings';

export const FileFormat = () => {
  return (
    <div>
      <div>
        <strong>File Formats Help:</strong>
      </div>
      <div>
        <a href={Env('file_format_url')} target="_blank" rel="noopener noreferrer">
          How do I view different file formats (PDF, DOC, PPT, MPEG) on this site?"
        </a>
      </div>
      <div>
        <span className="x24 fill-p cdc-icon-pdf mr-1"></span>
      </div>
    </div>
  );
};
