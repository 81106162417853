import React, { FC } from 'react';
import ReactHtmlParser from 'html-react-parser';

interface IProps {
  text: string | null | undefined;
  searchTerm: string | null;
}

export const SearchedText: FC<IProps> = ({ text, searchTerm }) => {
  if (!text) return <></>;

  if (!searchTerm) return <>{ReactHtmlParser(text)}</>;
  //if (!searchTerm) return <>{text}</>;

  // find the indexes of the term within the string.
  const match = text.match(new RegExp(searchTerm, 'i'));
  if (!match) return <>{ReactHtmlParser(text)}</>; // make it recursive so that everything is highlighted
  //if (!match) return <>{text}</>; // make it recursive so that everything is highlighted

  // split the string into 3 parts
  const termStart = match.index;
  const termEnd = match.index === undefined ? searchTerm.length : match.index + searchTerm.length;
  const firstPart = text.slice(0, termStart);
  const searchedTerm = text.slice(termStart, termEnd);
  const lastPart = text.slice(termEnd, text.length);
  // wrap the matching term in a span with a yellow background
  return (
    <>
      {ReactHtmlParser(firstPart)}
      <span className="search-highlight">{searchedTerm}</span>
      <SearchedText text={lastPart} searchTerm={searchTerm} />
    </>
  );
};
