import React, { FC, useState, useEffect, Fragment } from 'react';
import { IPageLevelFootnote } from '../models/PageLevelFootnote';
import { GetPageLevelFootnotes } from '../api/ComparisonData';
import ReactHtmlParser from 'html-react-parser';

interface IProps {
  viewType: string;
  locationCodes: string[];
}

export const FootNote: FC<IProps> = ({ viewType, locationCodes }) => {
  const [pageLevelFootnotes, setPageLevelFootnotes] = useState<IPageLevelFootnote[] | null>(null);

  //////////////////////////////////////// EFFECTS /////////////////////////////////////////////
  //1. Get pageLevelFootnotes
  useEffect(() => {
    if (!pageLevelFootnotes) {
      const getPageLevelFootNotesAPI = async () => {
        return await GetPageLevelFootnotes();
      };
      getPageLevelFootNotesAPI().then((res) => {
        if (res) {
          setPageLevelFootnotes(res);
        }
      });
    }
  }, [pageLevelFootnotes]);
  //////////////////////////////////////// FUNCTIONS //////////////////////////////////////////////
  const PageLevelFootnotesHeaders = () => {
    let pageLevelFootnotesHeaders: string[] = [...new Set(pageLevelFootnotes?.map((item) => item.footnoteHeading))]; // [ 'A', 'B']
    return pageLevelFootnotesHeaders;
  };
  function getFootNoteText(footNoteText: string, footNoteAccessed: string, footNoteUrl: string): string {
    if (footNoteUrl) {
      return `${footNoteText} ${footNoteAccessed}. URL: <a href="${footNoteUrl}" target="_blank">${footNoteUrl}</a>`;
    } else {
      return `${footNoteText} ${footNoteAccessed}`;
    }
  }

  //////////////////////////////////////// RETURN ///////////////////////////////////////
  return (
    <>
      {PageLevelFootnotesHeaders().map((headerText) => {
        let headerData = pageLevelFootnotes?.filter((row) => row.footnoteHeading === headerText);
        return (
          <Fragment key={headerText}>
            <div className="mt-3">
              <strong>{headerText}</strong>
            </div>
            {headerData?.map((row, i) => {
              return (
                <div className="ms-4" key={i}>
                  {ReactHtmlParser(getFootNoteText(row.footnoteText, row.accessed, row.url))}
                </div>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};
