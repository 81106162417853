import React from 'react';
import { Env } from '../AppSettings';

export const NavLink = () => {
  return (
    <div className="container-fluid site-title border-none p-0">
      <div className="container">
        <div className="row pt-1 pb-1">
          <div className="col-8">
            <nav className="d-flex justify-content-between">
              <a href={Env('cdc') + '/places/'}>Home</a>
            </nav>
          </div>
          <div className="col-4">
            <nav className="text-end">
              <a href={Env('dataportal')} target="_blank" rel="noopener noreferrer">
                Data Portal
              </a>{' '}
              |{' '}
              <a href={Env('cdc') + '/places/help/'} target="_blank" rel="noopener noreferrer">
                Help
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
