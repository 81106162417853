import React from 'react';
import { Env } from '../AppSettings';

const crumbs = [
  {
    label: 'CDC',
    route: Env('cdc'),
  },
  {
    label: 'Division of Population Health ',
    route: Env('cdc') + '/nccdphp/dph/',
  },
  {
    label: 'PLACES',
    route: Env('cdc') + '/places/',
  },
];

export const BreadCrumbs = () => {
  return (
    <div className="container">
      <div className="row mt-2 pb-2">
        <div className="col">
          <nav role="navigation" aria-label="Breadcrumb" className="d-none d-lg-block">
            <ol className="breadcrumb">
              {crumbs.map((crumb) => (
                <li key={crumb.label} className="breadcrumb-item">
                  <a href={crumb.route}>{crumb.label}</a>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
};
