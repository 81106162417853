import React, { FC, useState, useEffect } from 'react';
import { keyUpWrapper } from '../helper/common';
import { ICategory } from '../models/Category';
import { IStatesAndCounty } from '../models/StatesAndCounty';
import { ICompareData } from '../models/CompareData';
import { GetCategories } from '../api/ComparisonData';
import { GetCompareData } from '../api/ComparisonData';
import { CompareTableCounty } from './CompareTableCounty';
import { SearchedText } from './SearchedText';
import { ConfirmModal } from './ConfirmModal';
import { ConstantClass } from '../helper/ConstantClass';

interface IProps {
  locationCounties: IStatesAndCounty[] | null;
  countyCodes: string[];
  searchTextCounty: string | null;
  setCountyCodes: (fieldName: string[]) => void;
  setEditCountyCode: (fieldName: string) => void;
  setOpenCountyModal: (fieldName: boolean) => void;
  setSearchCountCounty: (fieldName: number) => void;
  firstLoad: boolean;
}

export const CompareCategoryCounty: FC<IProps> = ({
  locationCounties,
  countyCodes,
  searchTextCounty,
  setCountyCodes,
  setEditCountyCode,
  setOpenCountyModal,
  setSearchCountCounty,
  firstLoad,
}) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [compareDataAPI, setCompareDataAPI] = useState<ICompareData[] | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openConfirmModalClosed, setOpenConfirmModalClosed] = useState<boolean>(false);
  const [removeCountyCode, setRemoveCountyCode] = useState<string>('');
  const [removeCountyName, setRemoveCountyName] = useState<string>('');

  //////////////////////////////////////// EFFECTS /////////////////////////////////////////////
  //1. Get categories
  useEffect(() => {
    const getCategoriesAPI = async () => {
      return await GetCategories(ConstantClass.County, '');
    };
    getCategoriesAPI().then((res) => {
      if (res) {
        setCategories(res);
      }
    });
  }, []);

  //2.Get CompareData
  useEffect(() => {
    if (!firstLoad) {
      const getCompareDataAPI = async () => {
        return await GetCompareData(ConstantClass.County, getLocationCodes());
      };
      getCompareDataAPI().then((res) => {
        if (res) {
          // console.log('GetCompareDataCounty');
          // console.log(res);
          setCompareDataAPI(res);
        }
      });
    }
    //function
    function getLocationCodes() {
      let locCodes = '';
      if (countyCodes && countyCodes.length > 0) {
        locCodes = countyCodes.join(',');
      }
      return locCodes;
    }
  }, [firstLoad, countyCodes]);

  //3. Search
  useEffect(() => {
    if (searchTextCounty) {
      setSearchCountCounty(countMatches());
      //setOpenedTopics(createTopicsMap({ term: searchTerm }));
    }
    /////////////////////////
    function countMatches() {
      let count = 0;
      let match = null;

      if (searchTextCounty) {
        const pattern = new RegExp(searchTextCounty, 'gi');

        //1. categories
        categories.forEach((category) => {
          match = category.category.match(pattern);
          if (match) count += match.length;
        });
        //2. HeaderText
        if (searchTextCounty.toLowerCase() === 'measure') {
          count += categories.length;
        }
        if (searchTextCounty.toLowerCase() === 'data type') {
          count += categories.length;
        }
        match = getHeaderText('').match(pattern); //united states
        if (match) count += match.length * categories.length;
        if (countyCodes) {
          countyCodes.forEach((countyCode) => {
            match = getHeaderText(countyCode).match(pattern);
            if (match) count += match.length * categories.length;
          });
        }
        //3. compareDataAPI
        compareDataAPI?.forEach((data) => {
          //Indicator without year
          let match = data.indicator.match(pattern);
          if (match) count += match.length / 2;
          // /2 becuase there is adjacent row with the same name but hidden
          else {
            //Indicator with year
            match = (data.indicator + ' - ' + data.year).match(pattern);
            if (match) count += match.length / 2; // /2 becuase there is adjacent row with the same name but hidden
          }
          //DataType
          match = data.typeUnitCI.match(pattern);
          if (match) count += match.length;
          //HeaderText Location
          match = data.valueCI.match(pattern);
          if (match) count += match.length;
        });
        //
      }
      return count;
    }
    function getHeaderText(countyCode: string) {
      let headerText = '';
      if (countyCode) {
        let county = compareDataAPI?.find((row) => row.cityId?.toString() === countyCode);
        if (county) {
          headerText = county.columnHeaderText;
        }
      } else {
        let country = compareDataAPI?.find((row) => row.cityId === null);
        if (country) {
          headerText = country.columnHeaderText;
        }
      }
      return headerText;
    }
  }, [categories, compareDataAPI, countyCodes, searchTextCounty, setSearchCountCounty]);

  ////////////////////////////////////// FUNCTIONS ////////////////////////////////////////////
  function confirmModalReturn(answer: string) {
    if (answer === 'remove') {
      if (removeCountyCode) {
        let thisCountyCodes = countyCodes.filter((el) => el !== removeCountyCode);
        setCountyCodes(thisCountyCodes);
      }
    } else {
      setOpenConfirmModalClosed(true);
    }
  }
  //////////////////////////////////// HANDLE EVENTS ///////////////////////////////////////
  function CategoryClicked(e: React.MouseEvent<HTMLElement>, catCode: string) {
    //e.preventDefault();
    let pmIcon = document.getElementById(catCode);
    let tableDiv = document.getElementById(catCode + 'wrapper');
    if (pmIcon && tableDiv) {
      if (tableDiv.style.display === 'none') {
        tableDiv.style.display = 'block';
        pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(-180deg)';
      } else {
        tableDiv.style.display = 'none';
        pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(0deg)';
      }
    }
  }

  function SetOpenAll(e: React.MouseEvent<HTMLElement>, openAllCat: boolean) {
    categories?.forEach(({ catCode }) => {
      let pmIcon = document.getElementById(catCode);
      let tableDiv = document.getElementById(catCode + 'wrapper');
      if (pmIcon && tableDiv) {
        if (openAllCat) {
          tableDiv.style.display = 'block';
          pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(-180deg)';
        } else {
          tableDiv.style.display = 'none';
          pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(0deg)';
        }
      }
    });
  }
  //////////////////////////////////////// RETURN ///////////////////////////////////////
  return (
    <>
      <div className="gray-border">
        <div className="row m-0 pt-2 pb-2 cat-header">
          <div className="col-2">
            <strong>Category</strong>
          </div>
          <div className="col-10 expand-collase">
            <div>
              <span
                className="link px-1"
                role="button"
                tabIndex={0}
                onClick={(e) => SetOpenAll(e, true)}
                onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, true)}
              >
                Expand All
              </span>
              /
              <span
                className="link px-1"
                role="button"
                tabIndex={0}
                onClick={(e) => SetOpenAll(e, false)}
                onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, false)}
              >
                Collapse All
              </span>
            </div>
          </div>
        </div>
        <div className="mt-1">
          {categories?.map((category) => (
            <div key={category.catCode} className="topic-collapse gray-border pt-2 ps-2" role="button" tabIndex={0}>
              <div>
                <span
                  id={category.catCode}
                  role="button"
                  tabIndex={0}
                  className="fi cdc-icon-minus topic-collapse__plusminus"
                  aria-hidden="true"
                  onClick={(e) => CategoryClicked(e, category.catCode)}
                  onKeyUp={(e) => keyUpWrapper(e, CategoryClicked, category.catCode)}
                ></span>
                <label
                  htmlFor={category.catCode}
                  className="link px-1 ms-1 pb-0"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => CategoryClicked(e, category.catCode)}
                  onKeyUp={(e) => keyUpWrapper(e, CategoryClicked, category.catCode)}
                >
                  {/* <strong>{category.category}</strong> */}
                  <strong>
                    <SearchedText text={category.category} searchTerm={searchTextCounty} />
                  </strong>
                </label>
              </div>
              <div id={category.catCode + 'wrapper'} className="ms-4 mb-2">
                <CompareTableCounty
                  locationCounties={locationCounties}
                  compareData={compareDataAPI}
                  countyCodes={countyCodes}
                  catCode={category.catCode}
                  catName={category.category}
                  searchTextCounty={searchTextCounty}
                  setEditCountyCode={setEditCountyCode}
                  setOpenCountyModal={setOpenCountyModal}
                  setOpenConfirmModal={setOpenConfirmModal}
                  setRemoveCountyCode={setRemoveCountyCode}
                  setRemoveCountyName={setRemoveCountyName}
                  openConfirmModalClosed={openConfirmModalClosed}
                  setOpenConfirmModalClosed={setOpenConfirmModalClosed}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* /////////////////////////////// MODAL ///////////////////////////////////////// */}
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        removeLocationName={removeCountyName}
        confirmModalReturn={confirmModalReturn}
      />
    </>
  );
};
