import React from 'react';
import { mastheadOneCol, mastheadSubpage } from './assets/img/general_use';

export default () => (
  <div className="container-fluid header-wrapper">
    <div className="container">
      <header role="banner" aria-label="Header" className="pt-2 pb-2">
        <div className="row">
          <div className="col cdc-logo">
            <a href="https://www.cdc.gov">
              <span className="visually-hidden">
                Centers for Disease Control and Prevention. CDC twenty four seven. Saving Lives, Protecting People
              </span>
              <img alt="cdc logo" className="d-none d-md-block logo-large" src={mastheadSubpage} />
            </a>
            <a href="https://www.cdc.gov" tabIndex="-1">
              <span className="visually-hidden">
                Centers for Disease Control and Prevention. CDC twenty four seven. Saving Lives, Protecting People
              </span>
              <img alt="cdc logo" className="d-md-none logo-small" src={mastheadOneCol} />
            </a>
          </div>
        </div>
      </header>
    </div>
  </div>
);
